import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SignUp() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  let date = moment("02/18/2025").tz("Asia/Singapore");

  const [count, setCount] = useState({
    days: 0,
    hours: "00",
    minutes: "00",
    seconds: "00",
    timeUp: false,
  });

  useEffect(() => {
    setInterval(() => {
      let currentDate = moment().tz("Asia/Singapore");
      let difference = date - currentDate;

      if (difference < 1) {
        setCount({ timeUp: true });
      } else {
        let days = Math.floor(difference / (1000 * 60 * 60 * 24));
        let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        let minutes = Math.floor((difference / (1000 * 60)) % 60);
        // let seconds = Math.floor((difference / (1000)) % 60);
        setCount({
          hours: hours > 9 ? hours : `0${hours}`,
          minutes: minutes > 9 ? minutes : `0${minutes}`,
          // seconds: seconds > 9 ? seconds : `0${seconds}`,
          days,
        });
      }
    }, 1000);
  }, []);

  const { days, hours, minutes, timeUp } = count;
  const dayString = days > 1 ? "days" : "day";

  return (
    <>
      <div
        className="mt-4"
        style={{
          backgroundImage: "url(" + require("assets/img/bg11.jpg") + ")",
          // backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        }}
      >
        <Container>
            <div className="row">
              <div className="col-lg-2 offset-lg-1 col-md-6" align="center">
                <div className="inclusive">
                  <p>
                    <img
                      alt="..."
                      src={require("assets/img/icons/anywhere.png")}
                      className="icons-scheduler  img-responsive"
                    ></img>
                  </p>
                  <p>Access your itinerary anywhere</p>
                </div>
              </div>
               <div className="col-lg-2 col-md-6" align="center">
                <div className="inclusive">
                  <p>
                    {" "}
                    <img
                      alt="..."
                      src={require("assets/img/icons/filter.png")}
                      className="icons-scheduler  img-responsive"
                    ></img>
                  </p>
                  <p>Filter preferred countries and specialization</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6" align="center">
                <div className="inclusive">
                  <p>
                    <img
                      alt="..."
                      src={require("assets/img/icons/time.png")}
                      className="icons-scheduler  img-responsive"
                    ></img>
                  </p>
                  <p>Time saving and efficient</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6" align="center">
                <div className="inclusive">
                  <p>
                    <img
                      alt="..."
                      src={require("assets/img/icons/schedule.png")}
                      className="icons-scheduler  img-responsive"
                    ></img>
                  </p>
                  <p>Pre-schedule meetings with all delegates</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6" align="center">
                <div className="inclusive">
                  <p>
                    <img
                      alt="..."
                      src={require("assets/img/icons/partner.png")}
                      className="icons-scheduler img-responsive"
                    ></img>
                  </p>
                  <p>Arrange meetings with the right partners!</p>
                </div>
              </div> 
            </div>
            <Container className="text-center mt-5 pb-5">
              <Row className="justify-content-md-center">
                <Col lg="8" md="12">
                  <h2 className="font-weight-bold meeting-launch-title">
                    Launch of Meeting Scheduler{" "}
                  </h2>

                  {timeUp ? (
                    <p className="description">Event in progress</p>
                  ) : (
                    <div className="">
                      <div className="container">
                        <div className="d-flex flex-row justify-content-center">
                          <div className="d-flex flex-column mx-3 py-3 px-3 border text-black bg-white shadow">
                            <h2 className="mb-1">{`${days}`}</h2>
                            <span>{`${dayString}`}</span>
                          </div>
                          <div className="d-flex flex-column mx-3 py-3 px-3 border text-black bg-white shadow">
                            <h2 className="mb-1">{`${hours}`}</h2>
                            <span>hours</span>
                          </div>
                          <div className="d-flex flex-column mx-3 py-3 px-3 border text-black bg-white shadow">
                            <h2 className="mb-1">{`${minutes}`}</h2>
                            <span>minutes</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
            {/* <Container className="bg-white shadow mx-5 mt-4 mb-5" id="">
              <Row className="justify-content-center mt-5">
                <h1 className="font-weight-bold">Sponsors</h1>
              </Row>
              <Row className="mx-3 my-5 d-flex flex-lg-column row justify-content-center">
                  <div className="text-center platinum">
                      <h3 className="font-weight-bold">Platinum </h3>
                      <div className="d-md-flex justify-content-center" style={{gap: '30px 50px'}}>
                        <a target="_blank" href="https://softlinkglobal.com/">
                          <img
                              alt="Logi-Sys"
                              src={require("assets/img/sponsor/Logi-Sys-new.png")}
                              className="img-responsive"
                              width={350}
                            ></img>
                        </a>
                      </div>
                  </div>
                   <div className="text-center gold">
                      <h3 className="font-weight-bold">Gold</h3>
                      <div className="d-md-flex justify-content-center" style={{gap: '30px 50px'}}>
                        <a target="_blank" href="https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwi_997t0pL_AhWEV30KHRs5AqoYABACGgJzZg&ohost=www.google.com&cid=CAESbOD2Hyfi7x_e0IKM2yHEBGWSIx3Y7PeuizF0BqzavZ8fn-5YmOS0P6ZD44cpI1C0O9yd44EvxgElTe4hMWvHhHoY1GYPbsSvkwlZr80WcekkKuN4NAp-B5Uk4ddQXC-vznVPzswJw_Fw_nQ-XQ&sig=AOD64_09RxJznt32p7tWiMXA79ESOjiKlg&q&adurl&ved=2ahUKEwizytft0pL_AhXCwjgGHQ9-C2gQ0Qx6BAgKEAE">
                        <img
                              alt="QUOTISS"
                              src={require("assets/img/sponsor/QUOTISS.png")}
                              className="img-responsive"
                              width={250}
                            ></img>
                        </a>
                      <a target="_blank" href="https://www.nsrintermodal.com.cn">
                        <img
                              alt="nsr"
                              src={require("assets/img/sponsor/nsr.png")}
                              className="img-responsive"
                              width={250}
                            ></img>
                        </a>
                        <a target="_blank" href="#">
                        <img
                              alt="GOL logo"
                              src={require("assets/img/sponsor/GOL.png")}
                              className="img-responsive"
                              width={250}
                            ></img>
                        </a>
                      </div>
                  </div>
                  <div className="text-center mt-5">
                      <h3 className="font-weight-bold">Happy Hour</h3>
                      <div className="d-flex justify-content-center">
                        <a target="_blank" href="https://www.shipco.com/">
                        <img
                              alt="ShipcoTransportLogo"
                              src={require("assets/img/sponsor/ShipcoTransportLogo.png")}
                              className="img-responsive"
                              width={150}
                            ></img>
                        </a>
                      </div>
                  </div>
                  <div className="text-center mt-5">
                      <h3 className="font-weight-bold">Coffee Break</h3>
                      <div className="d-flex justify-content-center">
                        <a target="_blank" href="https://ecuworldwide.com/">
                        <img
                              alt="ShipcoTransportLogo"
                              src={require("assets/img/sponsor/ECU-Worldwide-Logo.png")}
                              className="img-responsive"
                              width={180}
                            ></img>
                        </a>
                        <a target="_blank" href="https://stlojistik.com.tr/en/">
                        <img
                              alt="ST GLOBAL FORWARDING & LOGISTIC SERVICES CO."
                              src={require("assets/img/sponsor/st.png")}
                              className="img-responsive"
                              width={200}
                            ></img>
                        </a>
                      </div>
                  </div>
                </Row>
            </Container>  */}

            {/* <Card className="card-signup" data-background-color="blue">
              <Form action="" className="form" method="">
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    Inquire
                  </CardTitle>
                  <div className="social-line">
                    <Button
                      className="btn-neutral btn-icon btn-round"
                      color="facebook"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon btn-round"
                      color="twitter"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="lg"
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon btn-round"
                      color="google"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-google-plus"></i>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={
                      "no-border" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="First Name..."
                      type="text"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons text_caps-small"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Last Name..."
                      type="text"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border" + (emailFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email..."
                      type="text"
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                    ></Input>
                  </InputGroup>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    className="btn-neutral btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Get Started
                  </Button>
                </CardFooter>
              </Form>
            </Card> */}
          {/* <div className="col text-center">
            <Button
              className="btn-round btn-white"
              color="default"
              to="/login-page"
              outline
              size="lg"
              tag={Link}
            >
              View Login Page
            </Button>
          </div> */}
        </Container>
      </div>
    </>
  );
}

export default SignUp;
